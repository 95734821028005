import { atom, AtomEffect, DefaultValue } from "recoil";

interface IAuthState {
  gameid: string;
  userid: string;
  random?: number;
}

const LOCAL_STORAGE_KEY = "auth:persist";

const persistAuthEffect: AtomEffect<IAuthState> = ({ onSet, setSelf }) => {
  if (typeof window !== "undefined") {
    const persistedValue = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (persistedValue) {
      setSelf(JSON.parse(persistedValue));
    }

    onSet(newValue => {
      if (newValue instanceof DefaultValue) {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
      } else {
        window.localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify(newValue)
        );
      }
    });
  }
};

export const authState = atom<IAuthState>({
  key: "authState",
  default: {
    gameid: "",
    userid: "",
    random: -1,
  },
  effects_UNSTABLE: [persistAuthEffect]
});
