/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ActionsResponse,
  CanimovePayload,
  CheckGamePlayload,
  CheckGameRequestData,
  CheckGameStartResponse,
  DistributionBatchData,
  GameRequestData,
  GameResponse,
  GetProductionBatch2Response,
  GetProductsResponse,
  JoinGameData,
  JoinGameResponse,
  MarkdownData,
  OverviewResponse,
  ProductionBatch,
  RequestData,
  ScoresResponse,
  SingleSlideRequest,
  SlideDiscoveryData,
  SlideDiscoveryResponse,
  SlideHintResponse,
  StepData,
  StepReponse
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description Get Game actions
   *
   * @tags api
   * @name PostActions
   * @request POST:/api/actions
   * @response `200` `ActionsResponse` Success
   * @response `404` `void` Game / Player not found in server
   */
  postActions = (payload: RequestData, params: RequestParams = {}) =>
    this.request<ActionsResponse, void>({
      path: `/api/actions`,
      method: "POST",
      body: payload,
      format: "json",
      ...params
    });
  /**
   * @description Check if all players are ready to move forward
   *
   * @tags api
   * @name PostCanIMove
   * @request POST:/api/canimove
   * @response `200` `CanimovePayload` Success
   */
  postCanIMove = (payload: StepData, params: RequestParams = {}) =>
    this.request<CanimovePayload, any>({
      path: `/api/canimove`,
      method: "POST",
      body: payload,
      format: "json",
      ...params
    });
  /**
   * @description Check if game started
   *
   * @tags api
   * @name PostCheckStartGame
   * @request POST:/api/check_start_game
   * @response `200` `CheckGameStartResponse` Success
   */
  postCheckStartGame = (payload: GameRequestData, params: RequestParams = {}) =>
    this.request<CheckGameStartResponse, any>({
      path: `/api/check_start_game`,
      method: "POST",
      body: payload,
      format: "json",
      ...params
    });
  /**
   * @description Check if game details in local store of user are valid
   *
   * @tags api
   * @name PostCheckGame
   * @request POST:/api/checkgame
   * @response `200` `CheckGamePlayload` Success
   * @response `404` `void` Game / Player not found in server
   */
  postCheckGame = (payload: CheckGameRequestData, params: RequestParams = {}) =>
    this.request<CheckGamePlayload, void>({
      path: `/api/checkgame`,
      method: "POST",
      body: payload,
      format: "json",
      ...params
    });
  /**
   * @description Check if combination of granularities leads to slide
   *
   * @tags api
   * @name PostSlideDiscovery
   * @request POST:/api/discover_slide
   * @response `200` `SlideDiscoveryResponse` Success
   */
  postSlideDiscovery = (
    payload: SlideDiscoveryData,
    params: RequestParams = {}
  ) =>
    this.request<SlideDiscoveryResponse, any>({
      path: `/api/discover_slide`,
      method: "POST",
      body: payload,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * @description GAMEMASTER - Usefull endpoint to get overall player information of a game room
   *
   * @tags api
   * @name PostGame
   * @request POST:/api/game
   * @response `200` `GameResponse` Success
   * @response `404` `void` Game does not exist
   */
  postGame = (payload: GameRequestData, params: RequestParams = {}) =>
    this.request<GameResponse, void>({
      path: `/api/game`,
      method: "POST",
      body: payload,
      format: "json",
      ...params
    });
  /**
   * @description Get Production Batch 2 items/workers
   *
   * @tags api
   * @name PostGetProductionBatch2
   * @request POST:/api/get_production_batch2
   * @response `200` `GetProductionBatch2Response` Success
   * @response `404` `void` Game / Player not found in server
   */
  postGetProductionBatch2 = (
    payload: RequestData,
    params: RequestParams = {}
  ) =>
    this.request<GetProductionBatch2Response, void>({
      path: `/api/get_production_batch2`,
      method: "POST",
      body: payload,
      format: "json",
      ...params
    });
  /**
   * @description Get product supply. Only valid when in Distribution Step
   *
   * @tags api
   * @name PostGetProducts
   * @request POST:/api/get_products
   * @response `200` `GetProductsResponse` Success
   * @response `404` `void` Game / Player not found in server
   */
  postGetProducts = (payload: RequestData, params: RequestParams = {}) =>
    this.request<GetProductsResponse, void>({
      path: `/api/get_products`,
      method: "POST",
      body: payload,
      format: "json",
      ...params
    });
  /**
   * @description Get the possible categories and granularities in the Slide Discovery
   *
   * @tags api
   * @name GetSlideHint
   * @request GET:/api/get_slide_hints
   * @response `200` `SlideHintResponse` Success
   */
  getSlideHint = (params: RequestParams = {}) =>
    this.request<SlideHintResponse, any>({
      path: `/api/get_slide_hints`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * @description Join one of the valid game rooms - game must not have started
   *
   * @tags api
   * @name PostJoinGame
   * @request POST:/api/joingame
   * @response `200` `JoinGameResponse` Success
   * @response `400` `void` Wrong secret code
   * @response `401` `void` Player already in game
   * @response `501` `void` Game is Already ongoing
   * @response `502` `void` Not a valid game room
   */
  postJoinGame = (payload: JoinGameData, params: RequestParams = {}) =>
    this.request<JoinGameResponse, void>({
      path: `/api/joingame`,
      method: "POST",
      body: payload,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * @description Kick User From Game
   *
   * @tags api
   * @name PostKickUser
   * @request POST:/api/kickuser
   * @response `200` `void` Successful
   */
  postKickUser = (payload: RequestData, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/kickuser`,
      method: "POST",
      body: payload,
      ...params
    });
  /**
   * @description Set Markdown per Item
   *
   * @tags api
   * @name PostOverview
   * @request POST:/api/overview
   * @response `200` `OverviewResponse` Success
   * @response `404` `void` Game / Player not found in server
   * @response `500` `void` Step not recognized
   */
  postOverview = (payload: RequestData, params: RequestParams = {}) =>
    this.request<OverviewResponse, void>({
      path: `/api/overview`,
      method: "POST",
      body: payload,
      format: "json",
      ...params
    });
  /**
   * @description Refresh secret code used to join a game
   *
   * @tags api
   * @name PostRefreshCode
   * @request POST:/api/refreshcode
   * @response `200` `void` Successful
   */
  postRefreshCode = (payload: GameRequestData, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/refreshcode`,
      method: "POST",
      body: payload,
      ...params
    });
  /**
   * @description Reselect Production Batch 2 items/workers
   *
   * @tags api
   * @name PostResetProductionBatch2
   * @request POST:/api/reset_production_batch2
   * @response `200` `void` Successful
   * @response `404` `void` Game / Player not found in server
   */
  postResetProductionBatch2 = (
    payload: ProductionBatch,
    params: RequestParams = {}
  ) =>
    this.request<void, void>({
      path: `/api/reset_production_batch2`,
      method: "POST",
      body: payload,
      ...params
    });
  /**
   * @description Reset Game Room
   *
   * @tags api
   * @name PostResetGame
   * @request POST:/api/resetgame
   * @response `200` `void` Successful
   */
  postResetGame = (payload: GameRequestData, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/resetgame`,
      method: "POST",
      body: payload,
      ...params
    });
  /**
   * @description Get Game scores
   *
   * @tags api
   * @name PostScores
   * @request POST:/api/scores
   * @response `200` `ScoresResponse` Success
   * @response `404` `void` Game / Player not found in server
   */
  postScores = (payload: RequestData, params: RequestParams = {}) =>
    this.request<ScoresResponse, void>({
      path: `/api/scores`,
      method: "POST",
      body: payload,
      format: "json",
      ...params
    });
  /**
   * @description Set Distribution Batch 1 trucks
   *
   * @tags api
   * @name PostSetDistributionBatch1
   * @request POST:/api/set_distribution_batch1
   * @response `200` `void` Successful
   * @response `404` `void` Game / Player not found in server
   */
  postSetDistributionBatch1 = (
    payload: DistributionBatchData,
    params: RequestParams = {}
  ) =>
    this.request<void, void>({
      path: `/api/set_distribution_batch1`,
      method: "POST",
      body: payload,
      ...params
    });
  /**
   * @description Set Distribution Batch 2 trucks
   *
   * @tags api
   * @name PostSetDistributionBatch2
   * @request POST:/api/set_distribution_batch2
   * @response `200` `void` Successful
   * @response `404` `void` Game / Player not found in server
   */
  postSetDistributionBatch2 = (
    payload: DistributionBatchData,
    params: RequestParams = {}
  ) =>
    this.request<void, void>({
      path: `/api/set_distribution_batch2`,
      method: "POST",
      body: payload,
      ...params
    });
  /**
   * @description Set Markdown per Item
   *
   * @tags api
   * @name PostSetMarkdown
   * @request POST:/api/set_markdown
   * @response `200` `void` Successful
   * @response `404` `void` Game / Player not found in server
   */
  postSetMarkdown = (payload: MarkdownData, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/api/set_markdown`,
      method: "POST",
      body: payload,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Set Production Batch 1 items/workers
   *
   * @tags api
   * @name PostSetProductionBatch1
   * @request POST:/api/set_production_batch1
   * @response `200` `void` Successful
   * @response `404` `void` Game / Player not found in server
   */
  postSetProductionBatch1 = (
    payload: ProductionBatch,
    params: RequestParams = {}
  ) =>
    this.request<void, void>({
      path: `/api/set_production_batch1`,
      method: "POST",
      body: payload,
      ...params
    });
  /**
   * @description Set Production Batch 2 items/workers
   *
   * @tags api
   * @name PostSetProductionBatch2
   * @request POST:/api/set_production_batch2
   * @response `200` `void` Successful
   * @response `404` `void` Game / Player not found in server
   */
  postSetProductionBatch2 = (
    payload: ProductionBatch,
    params: RequestParams = {}
  ) =>
    this.request<void, void>({
      path: `/api/set_production_batch2`,
      method: "POST",
      body: payload,
      ...params
    });
  /**
   * @description Get Specific Game Slide
   *
   * @tags api
   * @name PostSlide
   * @request POST:/api/slide
   * @response `200` `void` Successful
   */
  postSlide = (payload: SingleSlideRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/slide`,
      method: "POST",
      body: payload,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Get Game Slides
   *
   * @tags api
   * @name PostSlides
   * @request POST:/api/slides
   * @response `200` `void` Successful
   */
  postSlides = (payload: GameRequestData, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/slides`,
      method: "POST",
      body: payload,
      ...params
    });
  /**
   * @description Allows players to start the game when in online lobby mode
   *
   * @tags api
   * @name PostStartGame
   * @request POST:/api/start_game
   * @response `200` `void` Successful
   */
  postStartGame = (payload: GameRequestData, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/start_game`,
      method: "POST",
      body: payload,
      ...params
    });
  /**
   * @description Endpoint to set current step of player
   *
   * @tags api
   * @name PostStep
   * @request POST:/api/step
   * @response `200` `StepReponse` Success
   * @response `404` `void` Player does not exist
   */
  postStep = (payload: StepData, params: RequestParams = {}) =>
    this.request<StepReponse, void>({
      path: `/api/step`,
      method: "POST",
      body: payload,
      format: "json",
      ...params
    });
}
